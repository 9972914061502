function initFloatlabel() {
	jQuery('input:not(.no-floatlabel)').floatlabel({
		labelClass: 		'custom-label-floatlabel'
	});
	jQuery('textarea:not(.no-floatlabel)').floatlabel({
		labelClass: 		'custom-label-floatlabel'
	});
}

jQuery(document).ready(function(){
	initFloatlabel();
});
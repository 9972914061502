// Touch Swipe Start
jQuery(function() {
  jQuery(".swipe").swipe( {
    'allowPageScroll':"auto",

    //Generic swipe handler for all directions
    swipe:function(event, direction, distance, duration, fingerCount, fingerData) {
      if (direction === 'left') {
        jQuery('.right').click();
      }
      if (direction === 'right') {
        jQuery('.left').click();
      }
    }
  });
});
// Touch Swipe End
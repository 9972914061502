jQuery('.modal-trigger').click(function() {
	var title = jQuery(this).data('title');
	var content = jQuery(this).data('content');
	var link = jQuery(this).data('link');
	var linkContent = jQuery(this).data('link-content');

	var modal = jQuery('#multipurposeModal');

	modal.find('#modal-title').html(title);
	modal.find('#modal-content').html(content);
	modal.find('#modal-link').attr('href', link);
	modal.find('#modal-link').html(linkContent);
	
	modal.find('#modal-link').show();
	if (linkContent.length === 0 || link.length === 0) {
		jQuery('#multipurposeModal').find('#modal-link').hide();
	}
});